import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {disableTwoFactorAuth, enableTwoFactorAuth} from "../../actions/user";
import {withTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useInterval} from "../utils";


const mapStateToProps = state => {
  return {user: state.user.currentUser, _activeTab: state.tabs.tab};
};

const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#057E9D',
  },
  title: {
    color: '#fff',
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#fff',
  },
});

/* Animacija modalnega okna */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TwoStepVertificationDialog(props) {
  const classes = useStyles();
  const { onResend, onClose, open, t, user } = props;
  const [errorText, setErrorText] = useState(false);
  const [timeout, setTimeout] = useState(-1);
  const theme = useTheme();

  useEffect(() => {
    if(open) {
      setTimeout(9);
    }
  }, [open])

  // Odštevalnik za pošiljanje SMS kode
  useInterval(() => {
    setTimeout(timeout => timeout - 1);
  }, timeout > -1 ? 1000 : null);

  // Prelomnica za mobilne naprave
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeDialog = () => {
    setErrorText(false);
    onClose();
  };
  const isUserAuthEnabled = user.status.twoFactorAuthEnabled
  const textRef = useRef();
  const confirmSmsCode = () => {
    const smsCode = textRef.current.value

    if(smsCode === '') {
      return setErrorText(true);

    }
    const vertificationID = props.verificationId
    if(!isUserAuthEnabled) {
      props.dispatch(enableTwoFactorAuth(vertificationID, smsCode));
    } else if(isUserAuthEnabled) {
      props.dispatch(disableTwoFactorAuth(vertificationID, smsCode));
    }
    setErrorText(false);
    closeDialog();
  }

  const resendSmsCode = () => {
    setTimeout(9)
    setErrorText(false)
    onResend()
  }

  return (
    <Dialog
      classes={{paper: classes.dialog}}
      fullScreen={fullScreen}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="sms-confirm-dialog-title"
      open={open}
      TransitionComponent={Transition}>
      <DialogTitle classes={{root: classes.title}} id="sms-confirm-dialog-title">
          {isUserAuthEnabled ? t('twoFactorAuthTitleDisable') : t('twoFactorAuthTitleEnable')}
        <IconButton className={classes.closeButton} onClick={closeDialog} aria-label="delete">
          <CloseIcon  />
          </IconButton>
      </DialogTitle>
      <DialogContent>
          <DialogContentText classes={{root: classes.title}}>
          {isUserAuthEnabled ? t('twoFactorAuthContentDisable') : t('twoFactorAuthContentEnable')}
          </DialogContentText>
            <TextField
              autoFocus
              id="name"
              label={t('smsConfirmationCode')}
              type="text"
              inputRef={textRef}
              fullWidth
              error={errorText}
              helperText={errorText ? t('twoFactorAuthEmptyValue'): '' }
            />
        </DialogContent>
        <DialogActions>
          <button disabled={timeout > 0} className="button button--unelevated button--white" onClick={resendSmsCode}>
            {t('sendAgain')}
            {timeout > 0 ? <span style={{
                display: 'inline-block',
                opacity: 0.6,
                textAlign: 'right',
                width: 16,
              }}>{timeout}</span> : ""}
          </button>
          <button className="button button--unelevated button--white" onClick={confirmSmsCode}>
          {t('twoFactorAuthConfirm')}
          </button>
        </DialogActions>
    </Dialog>
  );
}
export default connect(mapStateToProps)(withTranslation("app")(TwoStepVertificationDialog));
