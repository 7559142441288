import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {changePassword, requestSmsToken} from "../actions/user";
import {API_URL} from "../config";
import {withTranslation} from "react-i18next";
import {useInterval} from "../components/utils";


const mapStateToProps = state => {
    return {
        user: state.user.currentUser,
        _activeTab: state.tabs.tab
    };
};

function AppUserChangePassword({t, dispatch, user}) {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState('');
    const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [verificationId, setVerificationId] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [timeout, setTimeout] = useState(-1);

    useEffect(() => {
        if (timeout === -1) {
            setTwoFactorAuthEnabled(user.status.twoFactorAuthEnabled === true)
            resendSmsCode()
        }
        setTimeout(9)
    }, [])

    // Odštevalnik za pošiljanje SMS kode
    useInterval(() => {
        setTimeout(timeout => timeout - 1);
    }, timeout > -1 ? 1000 : null);

    function _handleSubmit(e) {
        e.preventDefault();

        if (newPassword !== newPassword2) {
            setError(t('error-passwords-do-not-match'));
            return
        }

        setFetching(true)
        fetch(API_URL + "/passwordReset", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({code, newPassword, verificationId}),
        }).then(res => {
            if (res && res.message) {
                setError(t(res.message));
                setFetching(false);
                console.error(res.message)
            } else {
                setError('');
                setRedirect(true);
                setFetching(false);
            }
        }).catch(err => {
            setError(t('genericErrorMessage'));
            setFetching(false);
            console.error(err)
        });
    }

    const resendSmsCode = (isResend = false) => {
        setTimeout(9)
        setError('')

        dispatch(changePassword(isResend, res => {
            //console.log(res);
            if (res) {
                setVerificationId(res.Id)
            }
        }))
    }

    const onResendClick = () => {
        resendSmsCode(true)
    }

    if (redirect) return <Redirect to="/"/>;

    return (
        <form onSubmit={_handleSubmit}>
            <div className="Register-Content-Header">
                <div className="Register-Content-Body">
                    <div className="Register-Row">
                        <div className="Register-FormField">
                            <input type="text" name="code" className="Register-Input"
                                   value={code}
                                   onChange={e => setCode(e.target.value)}
                                   placeholder={t('smsConfirmationCode')} required
                                   autoComplete="one-time-code"/>
                        </div>
                    </div>
                    <button disabled={timeout > 0} className="Button Button--Wide"
                            onClick={onResendClick}>
                        {t('sendAgain')}
                        {timeout > 0 ? <span style={{
                            display: 'inline-block',
                            opacity: 0.6,
                            textAlign: 'right',
                            width: 16,
                        }}>{timeout}</span> : ""}
                    </button>
                    <div className="Register-Row-Spacing"/>
                    <div className="Register-Row">
                        <div className="Register-FormField">
                            <input type="password" name="newPassword" className="Register-Input"
                                   placeholder={t('newPassword')} required
                                   value={newPassword}
                                   onChange={e => setNewPassword(e.target.value)}
                                   autoComplete="new-password"/>
                        </div>
                    </div>
                    <div className="Register-Row">
                        <div className="Register-FormField Register-FormField--Next">
                            <input type="password" name="newPassword2" className="Register-Input"
                                   placeholder={t('repeatNewPassword')} required
                                   value={newPassword2}
                                   onChange={e => setNewPassword2(e.target.value)}
                                   autoComplete="new-password"/>

                            <button disabled={fetching} type="submit" data-loading={fetching}
                                    className="Button Button--Next">
                                <div className="Button__Loader"/>
                            </button>
                        </div>
                    </div>
                    <div className="Register-Row">
                        <span className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                    </div>
                    {/*<Trans i18nKey='resetPasswordHere'>
                            <span className="Register-Subtitle">Lost password? <Link className="link" to={"/recover"}>Reset password</Link></span>
                        </Trans>*/}
                </div>
            </div>
        </form>
    )
}

export default connect(mapStateToProps)(withTranslation("app")(AppUserChangePassword));