import React, {Component} from "react";
import {getAllWithdrawals} from "../actions/withdrawals";
import {getAccountBalance} from "../actions/accountBalance";
import {connect} from "react-redux";
import DepositBanner from "../components/DepositBanner";
import {ReactComponent as ArrowLeft} from "../images/arrow-left.svg";
import {formatDate, formatMoney} from "../components/utils";
import {Trans, withTranslation} from "react-i18next";
import {setActiveTab} from "../actions/tabs";
import {ReactComponent as InfoBtn} from "../images/round-info-button.svg";
import {ReactComponent as CloseBtn} from "../images/baseline-close-24px.svg";
import {requestSmsToken} from "../actions/user";
import {withdrawMoney} from "../actions/withdrawals";
import WithdrawConfirmDialog from './../components/dialogs/WithdrawConfirmDialog';
import {getAllDeposits} from "../actions/deposits";

const mapStateToProps = state => {
    return {
        totalBalance: state.accountBalance.accountBalance.totalBalance,
        availableFunds: state.accountBalance.accountBalance.availableFunds,
        withdrawalsTotal: state.accountBalance.accountBalance.withdrawalsTotal,
        maxWithdrawal: state.accountBalance.accountBalance.maxWithdrawal,
        futureTaxes: state.accountBalance.accountBalance.futureTaxes,
        user: state.user.currentUser,
        userConfirmed: state.user.currentUser.status.confirmed,
        withdrawals: state.withdrawals.withdrawals,

        withdrawalIds: state.withdrawals.withdrawalIds,
        atEnd: state.withdrawals.atEnd,
        direction: state.withdrawals.direction,
        sort: state.withdrawals.sort,
        limit: state.withdrawals.limit,
        offset: state.withdrawals.offset,

        _activeTab: state.tabs.tab
    };
};

class AppWithdraw extends Component {
    state = {
        open: false,
        verifiedId: null,
        amount: null,
    };

    render() {
        const {
            availableFunds,
            totalBalance,
            withdrawalsTotal,
            maxWithdrawal,
            futureTaxes,
            user,
            userConfirmed,
            withdrawals,
            t,
            atEnd
        } = this.props;
        return (
            <section className={this.props._activeTab === 1 ? "page page--next active" : "page page--next not-active"}>
                <div className="page__inner custom-page-dark-background">
                    <DepositBanner light/>
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-7-desktop mdc-layout-grid__cell--span-12-tablet">
                                <h2 className="mdc-typography--headline5 title title--border">{t('withdrawSummaryTitle')}</h2>
                                <i className="title-spacer"/>
                                <div className="table-responsive">
                                    <table id="sortable-table" className="sortable table table--no-wrap table--dark">
                                        <thead>
                                        <tr className="mdc-typography--body2 table__head-row">
                                            <th className="no-sort"/>
                                            <th>{t('status')}</th>
                                            <th>{t('date')}</th>
                                            <th className="hide-on-mobile">{t('transactionType')}</th>
                                            <th>{t('amount')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!atEnd && (
                                            <tr>
                                                <td colSpan="5" style={{textAlign: "center"}}>
                                                    <button
                                                        type="button"
                                                        onClick={this._loadMore.bind(this)}
                                                        className="button button--unelevated"
                                                        style={{
                                                            display: "inline-block",
                                                            padding: "5px",
                                                            height: "26px",
                                                            margin: "0",
                                                            lineHeight: "1",
                                                            minWidth: "48px"
                                                        }}>
                                                        ...
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                        {Object.keys(withdrawals).map(id => {
                                            const withdrawal = withdrawals[id];
                                            return (
                                                <tr className="mdc-typography--body1" key={id}>
                                                    {withdrawal.status === "PROCESSED" ? (
                                                        <td className="bullet green"/>) : (
                                                        <td className="bullet white"/>)
                                                    }
                                                    <td><Trans i18nKey={withdrawal.status}
                                                               ns={"app"}>{withdrawal.status}</Trans></td>
                                                    <td>{formatDate(withdrawal.date)}</td>
                                                    <td className="hide-on-mobile">{t('bankTransfer')}</td>
                                                    <td className="table-number">{formatMoney(withdrawal.value)} €</td>
                                                </tr>
                                            )
                                        })}
                                        <tr className="mdc-typography--body1 table__total">
                                            <td/>
                                            <td/>
                                            <td className="hide-on-mobile"/>
                                            <td><b>{t('total')}</b></td>
                                            <td><b>{formatMoney(withdrawalsTotal)} €</b></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/*<div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-1 no-small-screen"/>*/}
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-12-tablet">

                                <div ref="card" className="card card--dark card--flip">
                                    <div className="card__surface">
                                        <div
                                            className={"mdc-menu-surface--anchor card__more"}>
                                            <button ref="infoBtn"
                                                    className="icon-button icon-button--dense card__info-btn">
                                                <InfoBtn/>
                                            </button>
                                        </div>
                                        <div className="table-fixed">
                                            <h2 className="mdc-typography--headline5 title title--border">{t('withdrawNowTitle')}</h2>
                                            <i className="title-spacer"/>
                                            <div className="table-fixed">
                                                <table className="sortable table table--dark">
                                                    <tbody className="mdc-typography--body1">
                                                    <tr>
                                                        <td>{t('accountBalance')}</td>
                                                        <td className="table-number"
                                                            align="right">{formatMoney(totalBalance)} €
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('availableAmount')}</td>
                                                        <td className="table-number"
                                                            align="right">{formatMoney(availableFunds)} €
                                                        </td>
                                                    </tr>
                                                    {user.company ? '' : (
                                                        <tr>
                                                            <td>{t('taxes')}</td>
                                                            <td className="table-number"
                                                                align="right">{formatMoney(futureTaxes)} €
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td className="bold">{t('investorBankAccountNumber')}</td>
                                                        <td className="table-number bold"
                                                            align="right">{user.mainBankAccount ? user.mainBankAccount.accountNumber : '-'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('bankName')}</td>
                                                        <td align="right">{user.mainBankAccount ? user.mainBankAccount.bank : '-'}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="action-field__content-wrapper">
                                                <form onSubmit={this._withdraw.bind(this)}
                                                      className="action-field action-field--with-prefix">
                                                    <i className="action-field__prefix">€</i>
                                                    <input required type="number"
                                                           ref="withdrawInput"
                                                           className="action-field__input"
                                                           name="amount"
                                                           step=".01"
                                                           max={maxWithdrawal}
                                                           disabled={userConfirmed === false}
                                                           placeholder={t('max') + " " + formatMoney(maxWithdrawal)}/>
                                                    <button type="submit"
                                                            disabled={userConfirmed === false}
                                                            className="action-field__button button button--unelevated button--green">{t('withdrawAction')}
                                                    </button>
                                                </form>
                                                {userConfirmed ?
                                                    null :
                                                    <p className="mdc-typography--body2">{t("withdrawDenied")}</p>
                                                }

                                            </div>
                                            <WithdrawConfirmDialog
                                                open={this.state.open}
                                                onResend={this.dialogHandleResend}
                                                onClose={this.dialogHandleClose}
                                                verificationId={this.state.verifiedId}
                                                amount={this.state.amount}
                                            />
                                        </div>
                                        <div className="card__back surface--with-text">
                                            <h4 className="mdc-typography--subtitle2 surface__title">Info</h4>
                                            <div className="card__back-scroll-content">
                                                <p className="mdc-typography--body2"><Trans ns="app"
                                                                                            i18nKey="withdrawInfo"><br/></Trans>
                                                </p>
                                            </div>
                                            <div className="card__more">
                                                <button ref="closeBtn"
                                                        className="icon-button icon-button--dense icon-button--untouched card__info-btn">
                                                    <CloseBtn/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="custom-navigation-wrapper" ref="depositBtn">
                        <div className="custom-navigation">
                            <div className="custom-navigation__text">
                                <span>{t('depositSummaryTitle')}</span>
                            </div>
                            <div className="custom-navigation__icon">
                                <ArrowLeft/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    _withdraw(e) {
        e.preventDefault();
        let amount = parseFloat(this.refs.withdrawInput.value || "0") || .0;
        let ok = window.confirm(this.props.t("confirmWithdraw") + " " + formatMoney(amount) + "€?");
        if (ok) {
            // IF 2FA ENABLED
            if (this.props.user.status.twoFactorAuthEnabled === true) {
                // pošlje sms kodo za potrditev withdrawa in odpre dialog
                this.dispatchWithdrawalConfirmation(false, amount);
            }
            // IF 2FA DISABLED - potem samo withdraw money brez dialoga
            if (this.props.user.status.twoFactorAuthEnabled === false) {
                this.props.dispatch(withdrawMoney(amount, (event) => {
                }));
                this.refs.withdrawInput.value = '';
            }
        }
    }

    dispatchWithdrawalConfirmation = (isResend = false, amount = null) => {
        let finalAmount = amount || this.state.amount;

        this.props.dispatch(requestSmsToken('WITHDRAWAL_CONFIRMATION', isResend, (event) => {
            this.setState({verifiedId: event.Id, open: true, amount: finalAmount})
            this.refs.withdrawInput.value = ''
        }))
    }

    dialogHandleResend = () => {
        this.setState({open: false})
        this.dispatchWithdrawalConfirmation(true)
    }

    dialogHandleClose = () => {
        this.setState({open: false});
    };

    _loadMore() {
        this.props.dispatch(getAllWithdrawals({
            offset: this.props.offset + 1,
            sort: this.props.sort,
            direction: this.props.direction
        }));
    }

    componentDidMount() {
        this.refs.depositBtn.addEventListener('click', () => {
            this.props.dispatch(setActiveTab(0))
        });
        this.props.dispatch(getAllWithdrawals());
        this.props.dispatch(getAccountBalance());

        // card flipping
        this._infoListener = e => {
            this.refs.card.classList.add("card--is-flipped")
        };
        this._closeListener = e => {
            this.refs.card.classList.remove("card--is-flipped")
        };
        this.refs.infoBtn.addEventListener('click', this._infoListener);
        this.refs.closeBtn.addEventListener('click', this._closeListener);
    }
}

export default connect(mapStateToProps)(withTranslation('app')(AppWithdraw));
