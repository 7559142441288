import React, {Component} from "react";
import ReactDOM from "react-dom";
import {ReactComponent as ArrowRight} from "../images/arrow-right.svg";
import {connect} from "react-redux";
import {MDCSelect} from "@material/select";
import {MDCSwitch} from "@material/switch";
import DepositBanner from "../components/DepositBanner";
import {requestSmsToken, updateUserLanguage, updateUserNotifications} from "../actions/user";
import InvestorProfileBanner from "../components/InvestorProfileBanner";
import TwoStepVertificationDialog from "../components/dialogs/TwoFactorAuth";
import {Trans, withTranslation} from "react-i18next";
import {Link, Redirect} from "react-router-dom";
import DeleteAccountDialog from "../components/dialogs/DeleteAccount";

const mapStateToProps = state => {
    return {
        user: state.user.currentUser,
        totalBalance: state.accountBalance.accountBalance.totalBalance,
        _activeTab: state.tabs.tab
    };
};

class AppAccount extends Component {
    state = {
        open: false,
        openDeleteAccount: false,
        verifiedId: null,
    };

    render() {
        const {user, t} = this.props,
            accountSummary = user.settings.notifications ? user.settings.notifications.accountSummary : '';

        return (
            <section className={this.props._activeTab === 0 ? "page page--prev active" : "page page--prev not-active"}>
                {this.state.redirect ? <Redirect push to={this.state.redirectUrl}/> : <div/>}
                <div className="page__inner">
                    {user.status.confirmed === true && ((user.investorType === "Personal" && user.investorProfile && user.investorProfile.completed) || (user.investorType === "Business" && user.companyInvestorProfile && user.companyInvestorProfile.completed)) ? (
                        <DepositBanner/>
                    ) : (
                        <InvestorProfileBanner/>
                    )}
                    <div className="mdc-layout-grid">
                        <div className="mdc-layout-grid__inner">
                            <div
                                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet">
                                <h2 className="mdc-typography--headline5 title title--border">{t('personalDataAndSecurityTitle')}</h2>
                                <i className="title-spacer"/>
                                <div className="list list--vertical list--ordinal-background">
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('accountIDNumber')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.reference.number}</b></span>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('firstName')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.contact.firstName}</b></span>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('lastName')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.contact.lastName}</b></span>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('address')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.address.streetName}, {user.address.townOrSuburb}, {user.address.regionOrState}</b></span>
                                    </div>
                                    {/*<div className="list-item">
                                        <span className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                        <span className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                    </div>*/}
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('email')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.contact.email}</b></span>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('phone')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.contact.telephone}</b></span>

                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('idDocumentValidityDate')}</span>
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{user.identificationDocument?.validTo ? user.identificationDocument.validTo : t("N/A")}</b></span>

                                    </div>

                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('defaultLanguage')}</span>
                                        <div
                                            className="list-item list-item__text custom-account-list-item">
                                            <div className="form-field">
                                                <div ref="language"
                                                     className="mdc-select mdc-select--outlined mdc-select--no-border">
                                                    <input type="hidden" name="enhanced-select"/>
                                                    <i className="mdc-select__dropdown-icon"/>
                                                    <div
                                                        className="mdc-select__selected-text mdc-typography--body1">{user.settings.language === "EN" ? t('english') : t('slovene')}
                                                    </div>
                                                    <div
                                                        className="mdc-select__menu mdc-menu mdc-menu-surface demo-width-class">
                                                        <ul className="mdc-list">
                                                            <li className={user.settings.language === "EN" ? "mdc-list-item mdc-list-item--selected" : "mdc-list-item"}
                                                                data-value="en"
                                                                aria-selected={user.settings.language === "EN"}>
                                                                {t('english')}
                                                            </li>
                                                            <li className="mdc-list-divider"/>
                                                            <li className={user.settings.language === "SL" ? "mdc-list-item mdc-list-item--selected" : "mdc-list-item"}
                                                                data-value="sl"
                                                                aria-selected={user.settings.language === "SL"}>
                                                                {t('slovene')}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="list-item">
                                        <span className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                        <span className="mdc-typography--body1 list-item__text custom-account-list-item"/>
                                    </div>*/}

                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('twoFactorAuth')}</span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item">
                                            <button
                                                onClick={this.toggleTwoFactorAuth}
                                                className="button button--unelevated button--primary">
                                                {user.status.twoFactorAuthEnabled ? t('disable') : t('enable')}</button>
                                        </div>
                                    </div>
                                    <TwoStepVertificationDialog open={this.state.open}
                                                                onResend={this.dialogHandleResend}
                                                                onClose={this.dialogHandleClose}
                                                                verificationId={this.state.verifiedId}/>

                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('changePassword')}</span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item">
                                            <Link to="/u/change-password"
                                                  className="button button--unelevated button--primary">{t('changePassword')}</Link>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item">{t('deleteAccount')}</span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item">
                                            <button onClick={this.deleteAccount} className="button button--unelevated button--primary">{t('delete')}</button>
                                        </div>
                                    </div>
                                    <DeleteAccountDialog open={this.state.openDeleteAccount}
                                                         onResend={this.deleteAccountDialogHandleResend}
                                                         onClose={this.deleteAccountDialogHandleClose}
                                                         verificationId={this.state.verifiedId} />
                                </div>
                            </div>
                            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                                <h2 className="mdc-typography--headline5 title title--border">{t('notificationsTitle')}</h2>
                                <i className="title-spacer"/>
                                <div className="list list--vertical list--ordinal-background">
                                    {/*<div className="list-item">
                                        <span className="mdc-typography--body1 list-item__text custom-account-list-item"><b>Account summary</b></span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                            <div className={user.settings.notifications && user.settings.notifications.accountSummary ? "mdc-switch mdc-switch--checked" : "mdc-switch"}>
                                                <div className="mdc-switch__track"/>
                                                <div className="mdc-switch__thumb-underlay">
                                                    <div className="mdc-switch__thumb">
                                                        <input ref="summary" type="checkbox" className="mdc-switch__native-control"
                                                               role="switch" defaultChecked={user.settings.notifications && user.settings.notifications.accountSummary === true}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('accountSummaryNotifications')}</b></span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                            <div className="form-field">
                                                <div ref="summary"
                                                     className="mdc-select mdc-select--outlined mdc-select--no-border">
                                                    <input type="hidden" name="enhanced-select"/>
                                                    <i className="mdc-select__dropdown-icon"/>
                                                    <div className="mdc-select__selected-text mdc-typography--body1">
                                                        <Trans i18nKey={accountSummary}>{accountSummary}</Trans>
                                                    </div>
                                                    <div
                                                        className="mdc-select__menu mdc-menu mdc-menu-surface">
                                                        <ul className="mdc-list">
                                                            <li className={accountSummary === "NONE" ? "mdc-list-item mdc-list-item--selected" : "mdc-list-item"}
                                                                data-value="NONE"
                                                                aria-selected={accountSummary === "NONE"}>
                                                                <Trans i18nKey={"NONE"}>None</Trans>
                                                            </li>
                                                            <li className="mdc-list-divider"/>
                                                            <li className={accountSummary === "DAILY" ? "mdc-list-item mdc-list-item--selected" : "mdc-list-item"}
                                                                data-value="DAILY"
                                                                aria-selected={accountSummary === "DAILY"}>
                                                                <Trans i18nKey={"DAILY"}>Daily</Trans>
                                                            </li>
                                                            <li className="mdc-list-divider"/>
                                                            <li className={accountSummary === "WEEKLY" ? "mdc-list-item mdc-list-item--selected" : "mdc-list-item"}
                                                                data-value="WEEKLY"
                                                                aria-selected={accountSummary === "WEEKLY"}>
                                                                <Trans i18nKey={"WEEKLY"}>Weekly</Trans>
                                                            </li>
                                                            <li className="mdc-list-divider"/>
                                                            <li className={accountSummary === "MONTHLY" ? "mdc-list-item mdc-list-item--selected" : "mdc-list-item"}
                                                                data-value="MONTHLY"
                                                                aria-selected={accountSummary === "MONTHLY"}>
                                                                <Trans i18nKey={"MONTHLY"}>Monthly</Trans>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('depositsNotifications')}</b></span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                            <div
                                                className={user.settings.notifications && user.settings.notifications.deposits ? "mdc-switch mdc-switch--checked" : "mdc-switch"}>
                                                <div className="mdc-switch__track"/>
                                                <div className="mdc-switch__thumb-underlay">
                                                    <div className="mdc-switch__thumb">
                                                        <input ref="deposits" type="checkbox"
                                                               className="mdc-switch__native-control"
                                                               role="switch"
                                                               defaultChecked={user.settings.notifications && user.settings.notifications.deposits === true}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('withdrawalsNotifications')}</b></span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                            <div
                                                className={user.settings.notifications && user.settings.notifications.withdrawals ? "mdc-switch mdc-switch--checked" : "mdc-switch"}>
                                                <div className="mdc-switch__track"/>
                                                <div className="mdc-switch__thumb-underlay">
                                                    <div className="mdc-switch__thumb">
                                                        <input ref="withdrawals" type="checkbox"
                                                               className="mdc-switch__native-control"
                                                               role="switch"
                                                               defaultChecked={user.settings.notifications && user.settings.notifications.withdrawals === true}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('newsNotifications')}</b></span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                            <div
                                                className={user.settings.notifications && user.settings.notifications.news ? "mdc-switch mdc-switch--checked" : "mdc-switch"}>
                                                <div className="mdc-switch__track"/>
                                                <div className="mdc-switch__thumb-underlay">
                                                    <div className="mdc-switch__thumb">
                                                        <input ref="news" type="checkbox"
                                                               className="mdc-switch__native-control"
                                                               role="switch"
                                                               defaultChecked={user.settings.notifications && user.settings.notifications.news === true}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item">
                                        <span
                                            className="mdc-typography--body1 list-item__text custom-account-list-item"><b>{t('updatesNotifications')}</b></span>
                                        <div
                                            className="mdc-typography--body1 list-item__other custom-account-list-item custom-account-list-item--adjust-end">
                                            <div
                                                className={user.settings.notifications && user.settings.notifications.updates ? "mdc-switch mdc-switch--checked" : "mdc-switch"}>
                                                <div className="mdc-switch__track"/>
                                                <div className="mdc-switch__thumb-underlay">
                                                    <div className="mdc-switch__thumb">
                                                        <input ref="updates" type="checkbox"
                                                               className="mdc-switch__native-control"
                                                               role="switch"
                                                               defaultChecked={user.settings.notifications && user.settings.notifications.updates === true}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="custom-navigation-wrapper" ref="investorBtn">
                        <div className="custom-navigation">
                            <div className="custom-navigation__text">
                                <span>{t('investorProfileTitle')}</span>
                            </div>
                            <div className="custom-navigation__icon">
                                <ArrowRight/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    dialogHandleClose = (value) => {
        this.setState({open: false});
    };

    dialogHandleResend = (value) => {
        this.setState({open: false});
        this.dispatchToggleTwoFactorAuth(true)
    };

    toggleTwoFactorAuth = () => {
        this.dispatchToggleTwoFactorAuth(false);
    }

    dispatchToggleTwoFactorAuth = (isResend = false) => {
        this.props.dispatch(requestSmsToken('TWO_FACTOR_CHANGE', isResend, (event) => {
            this.setState({verifiedId: event.Id, open: true});
        }));
    }

    deleteAccountDialogHandleResend = (value) => {
        this.setState({openDeleteAccount: false});
        this.dispatchDeleteAccount(true)
    };

    deleteAccountDialogHandleClose = (value) => {
        this.setState({openDeleteAccount: false});
    };

    deleteAccount = () => {
        this.dispatchDeleteAccount(false)
    }

    dispatchDeleteAccount = (isResend = false) => {
        if (this.props.totalBalance >= 1) {
            window.alert(this.props.t('deleteAccountErrorAccountValue'));
            return;
        }

        this.props.dispatch(requestSmsToken('DELETE_ACCOUNT', isResend, (event) => {
            this.setState({verifiedId: event.Id, openDeleteAccount: true});
        }));
    }

    componentDidMount() {
        const language = new MDCSelect(this.refs.language);
        language.listen('MDCSelect:change', () => {
            this.props.dispatch(updateUserLanguage(language.value));
        });
        this.refs.investorBtn.addEventListener('click', () => {
            document.getElementsByClassName('page--prev')[0].classList.toggle('not-active');
            document.getElementsByClassName('page--next')[0].classList.toggle('active');
        });

        ReactDOM.findDOMNode(this).querySelectorAll('.mdc-switch').forEach(el => {
            new MDCSwitch(el)
        });

        const summary = new MDCSelect(this.refs.summary);
        summary.listen('MDCSelect:change', () => {
            this.props.dispatch(updateUserNotifications("accountSummary", summary.value));
        });

        this.refs.deposits.addEventListener('change', e => {
            this.props.dispatch(updateUserNotifications("deposits", this.refs.deposits.checked));
        });
        this.refs.withdrawals.addEventListener('change', e => {
            this.props.dispatch(updateUserNotifications("withdrawals", this.refs.withdrawals.checked));
        });
        this.refs.news.addEventListener('change', e => {
            this.props.dispatch(updateUserNotifications("news", this.refs.news.checked));
        });
        this.refs.updates.addEventListener('change', e => {
            this.props.dispatch(updateUserNotifications("updates", this.refs.updates.checked));
        })

    }
}

export default connect(mapStateToProps)(withTranslation("app")(AppAccount));
